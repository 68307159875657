<template>
  <div class="mt-5">
    <div class="d-flex">
      <p v-if="produtoEncontrado" class="lead">
        <img :src="require(`@/assets/images/logo-mini.jpeg`)" class="mr-2" alt="" style="width: 32px">
        <h2>{{ produtoEncontrado.nome }}</h2>
      </p>
    </div>
    <div class="row">
      <div v-if="produtoEncontrado" class="row">
        <div class="col-12 col-lg-7">
          <div class="row no-gutters d-flex align-items-center justify-content-center" @click="index=0">
            <b-carousel
                id="carousel"
                :interval="5000"
                @sliding-start="onSlideStart"
                @slidind-end="onSlideEnd"
                controls
                indicators
                class="img img-fluid-lg"
                >
                  <b-carousel-slide
                      v-for="(foto, index) in produtoEncontrado.fotos.galeria"
                      v-bind:key="index"
                      :img-src="foto"
                      class="img-size-fluid"
                      style="cursor: pointer"
                  ></b-carousel-slide>
                </b-carousel>
          </div>
        </div>
        <div class="col-12 col-lg-5 mt-lg-0 mt-5">
          <div class="col-12">
            <Orcamento
                :identificador="produtoEncontrado.url"
                :titulo="produtoEncontrado.nome"
                class="mb-5"
            ></Orcamento>
            <b-tabs
                active-nav-item-class="tab-active text-white"
                content-class="text-muted mt-3 px-2"
                fill
            >
              <b-tab title="Características" title-item-class="">
                {{ produtoEncontrado.detalhes.caracteristica }}
              </b-tab>
              <b-tab title="Modelos de Tampas" title-item-class="">
                <p v-for="(item, index) in produtoEncontrado.detalhes.tampa" v-bind:key="index">
                  {{ item }}
                </p>
              </b-tab>
              <b-tab title="Detalhes Técnicos" title-item-class="">
                <p v-for="(item, index) in produtoEncontrado.detalhes.tecnico" v-bind:key="index">
                  {{ item }}
                </p>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
      <div class="row col-12" v-else>
        <p class="col-12">Desculpe, produto não encontrado!</p>
      </div>
    </div>
    <div class="w-100 h-100 my-4 px-3">
      <CoolLightBox
          :items="produtoEncontrado.fotos.galeria"
          :index="index"
          @close="index = null"
          style="backdrop-filter: blur(7px); background-color: rgba(255, 255, 255, .50)"
      >
      </CoolLightBox>
    </div>
  </div>
</template>

<script>

import Orcamento from "@/components/Orcamento.vue";

import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'

export default {
  components: {
    Orcamento,
    CoolLightBox
  },
  data() {
    return {
      produtos: [
        {
          id: 1,
          repetido: false,
          segmento: "automobilistico",
          descricao_segmento: "Automobilísta",
          tamanho: "1000",
          unidade_medida: "ml",
          nome: "Frasco Plástico 1000ml Inovah",
          descricao:
              "Frasco plástico para envase de aditivo de radiadores automotivos e óleo/silicone para lubrificação...",
          fotos: {
            capa: require('@/assets/images/products/cover/frasco_inovah_1000.png'),
            detalhe: "frasco_inovah_1000.png",
            galeria: [
              require('@/assets/images/products/frasco_inovah_1000.png'),
              require('@/assets/images/products/cover/frasco_inovah_1000.png')
            ]
          },
          url: "frasco-plastico-1000ml-inovah",
          detalhes: {
            caracteristica:
                "Frasco Inovah é uma embalagem de PEAD produzida pela tecnologia de sopro, qualidade incomparável. Possui tampa com batoque e lacre, podendo-se também aplicar selo de indução, garantindo assim a segurança do seu produto.",
            tampa: [
              "Tampa: Pressão e Rosca (sob consulta)"
            ],
            tecnico: [
              "Gramatura de linha : 45g (demais gramaturas sob consulta)",
              "Material: PEAD (Polietileno de alta densidade) novo ou reciclável",
              "Cores de linha: Natural e branco (demais cores sob consulta)"
            ],
          },
        },
        {
          id: 2,
          repetido: false,
          segmento: "automobilistico",
          descricao_segmento: "Automobilísta",
          tamanho: "500",
          unidade_medida: "ml",
          nome: "Frasco Plástico 500ml Cilíndrico Batoque",
          descricao:
              "Frasco plástico usado para aditivos de motor, óleo/silicone para lubrificação, envase de produtos farmacêuticos ...",
          fotos: {
            capa: require('@/assets/images/products/cover/frasco_cili_500.png'),
            detalhe: "frasco_cili_500.png",
            galeria: [
              require('@/assets/images/products/frasco_cili_500.png'),
              require('@/assets/images/products/cover/frasco_cili_500.png')
            ]
          },
          url: "frasco-plastico-500ml-cilindrico-batoque",
          detalhes: {
            caracteristica:
                "Cili é uma embalagem de PEAD,produzida pela tecnologia a sopro, qualidade incomparável, sua tampa com lacre, é ideal para conservar, garantindo assim a segurança de seu produtos.",
            tampa: [
              "Tampa: Batoque c/ lacre"
            ],
            tecnico: [
              "Gramatura de linha : 45g (outras gramaturas sob consulta)",
              "Material: PEAD (Polietileno de alta densidade) Novo ou Reciclável",
              "Cores de linha: Natural e branco (outras cores sob consulta)"
            ],
          },
        },
        {
          id: 3,
          repetido: false,
          segmento: "automobilistico",
          descricao_segmento: "Automobilísta",
          tamanho: "1000",
          unidade_medida: "ml",
          nome: "Frasco Plástico 1000ml Cilíndrico",
          descricao:
              "Frasco plástico para envase de aditivos de motor, produtos farmacêuticos, óleo/silicone para lubrificação, cola branca, produtos químicos e de limpeza em geral ...",
          fotos: {
            capa: require('@/assets/images/products/cover/frasco_cili_1000.png'),
            detalhe: "frasco_cili_1000.png",
            galeria: [
              require('@/assets/images/products/frasco_cili_1000.png'),
              require('@/assets/images/products/cover/frasco_cili_1000.png')
            ]
          },
          url: "frasco-plastico-1000ml-cilindrico",
          detalhes: {
            caracteristica:
                "Cili é uma embalagem de PEAD produzida pela tecnologia sopro, qualidade incomparável, sua tampa com lacre, é ideal para conservar e evitar vazamentos, garantindo assim a segurança do seu produto.",
            aplicacao:
                "Muito usado para envase de aditivos de radiador, óleo/silicone para lubrificação, cola branca, e produtos químicos e de limpeza em geral.",
            tampa: [
              "Tampa: Batoque c/ lacre"
            ],
            tecnico: [
              "Gramatura de linha : 45g (outras gramaturas sob consulta)",
              "Material: PEAD (Polietileno de alta densidade) Novo ou Reciclável",
              "Cores de linha: Natural e branco (outras cores sob consulta)"
            ],
          },
        },
        {
          id: 4,
          repetido: false,
          segmento: "automobilistico",
          descricao_segmento: "Automobilísta",
          tamanho: "1000",
          unidade_medida: "ml",
          nome: "Frasco Plástico 1000ml Quadrado 32",
          descricao:
              "Frasco plástico para envase de aditivos de motor, óleo/silicone para lubrificação, cola branca, produtos farmacêuticos, e produtos químicos e de limpeza em geral...",
          fotos: {
            capa: require('@/assets/images/products/cover/quadrado_32.jpg'),
            detalhe: "quadrado_32.jpg",
            galeria: [
              require('@/assets/images/products/quadrado_32.jpg'),
              require('@/assets/images/products/cover/quadrado_32.jpg')
            ]
          },
          url: "frasco-plastico-1000ml-quadrado-32",
          detalhes: {
            caracteristica:
                "Frasco plástico 1000ml quadrado 32 é uma embalagem de PEAD produzida pela tecnologia sopro, qualidade incomparável, sua tampa com lacre, é ideal para conservar, garantindo assim a segurança de seu produtos, sendo utilizado também com selo de indução.",
            aplicacao:
                "Muito usado para envase de aditivos de motor, óleo/silicone para lubrificação, cola branca, produtos farmacêuticos, e produtos químicos e de limpeza em geral.",
            tecnico: "| Peso: 45 gramas (personalizavel) |  Material: PEAD (Polietileno de alta densidade) Novo ou Reciclavel | Tampa: Batoque c/ lacre  | Cores: Natural, branco, cor | Dimensões: 200mm x 94mm",
          },
        },
        {
          id: 5,
          repetido: false,
          segmento: "automobilistico",
          descricao_segmento: "Automobilísta",
          tamanho: "500",
          unidade_medida: "ml",
          nome: "Pote Plástico 500ml Inovah Bio Rosca",
          descricao: "",
          fotos: {
            capa: require('@/assets/images/products/cover/bio_b_500.png'),
            detalhe: "bio_b_500.png",
            galeria: [
              require('@/assets/images/products/bio_b_500.png'),
              require('@/assets/images/products/cover/bio_b_500.png')
            ]
          },
          url: "pote-plastico-500ml-inovah-bio-rosca",
          detalhes: {
            caracteristica:
                "Bio é um pote de PEAD produzida pela tecnologia a sopro, qualidade incomparável, sua tampa com rosca faz com que você possa abrir e fechar diversas vezes, garantindo assim a segurança de seu produtos.",
            tampa: [
              "Tampa: Rosca (sob consulta)"
            ],
            tecnico: [
              "Gramatura de linha : 26g (demais gramaturas sob consulta)",
              "Material: PEAD (Polietileno de alta densidade) novo ou reciclável",
              "Cores de linha: Natural e branco (demais cores sob consulta)"
            ],
          },
        },
        {
          id: 6,
          repetido: false,
          segmento: "automobilistico",
          descricao_segmento: "Automobilísta",
          tamanho: "1000",
          unidade_medida: "ml",
          nome: "Pote Plástico 1000ml Inovah Bio B",
          descricao:
              "Pote plástico para envase de cremes, produtos farmacêuticos, cosméticos e para envase de produtos de limpeza em geral...",
          fotos: {
            capa: require('@/assets/images/products/cover/bio_b_1000.png'),
            detalhe: "bio_b_1000.png",
            galeria: [
              require('@/assets/images/products/bio_b_1000.png'),
              require('@/assets/images/products/cover/bio_b_1000.png')
            ]
          },
          url: "pote-plastico-1000ml-inovah-bio-b",
          detalhes: {
            caracteristica:
                "Bio é um pote de PEAD produzida pela tecnologia a sopro, qualidade incomparável, sua tampa de rosca é indicado para envase de produto o qual necessita ser aberto e fechado inúmeras vezes.",
            aplicacao:
                "Muito procurado no envase de cremes, produtos farmacêuticos, cosméticos e para envase de produtos de limpeza em geral.",
            tecnico:
                "Utilizações: Cosmética, artesanal e escolar | Peso: 47 gramas | Capacidade: 1 quilo | Material: PEAD novo | Tampa: rosca, cor preta | Cores: Natural, branco, cor | Dimensões: 135 mm x 105 mm | Impressões: 100 mm Ø | Peso: 45 gramas (personalizavel) |  Material: PEAD (Polietileno de alta densidade) Novo ou Reciclavel | Tampa: Reta (baixa) de rosca  | Cores: Natural, branco, cor | Dimensões: 135mm x 105mm",
          },
        },
        {
          id: 7,
          repetido: false,
          segmento: "automobilistico",
          descricao_segmento: "Automobilísta",
          tamanho: "1000",
          unidade_medida: "ml",
          nome: "Pote Plástico 1000ml Inovah Bio A",
          descricao:
              "Pote plástico para envase de produtos farmacêuticos, cremes, cosméticos e para envase de produtos de limpeza em geral...",
          fotos: {
            capa: require('@/assets/images/products/cover/bio_a.png'),
            detalhe: "bio_a.png",
            galeria: [
              require('@/assets/images/products/bio_a.png'),
              require('@/assets/images/products/cover/bio_a.png')
            ]
          },
          url: "pote-plastico-1000ml-inovah-bio-a",
          detalhes: {
            caracteristica:
                "Bio é um pote de PEAD produzida pela tecnologia a sopro, qualidade incomparável, sua tampa de rosca é indicado para envase de produto o qual necessita ser aberto e fechado inúmeras vezes.",
            tampa: [
              "Tampa: Batoque c/ lacre"
            ],
            tecnico: [
              "Gramatura de linha : 45g (demais gramaturas sob consulta)",
              "Material: PEAD (Polietileno de alta densidade) novo ou reciclável",
              "Cores de linha: Natural e branco (demais cores sob consulta)"
            ],
          },
        },
        {
          id: 8,
          repetido: true,
          segmento: "cosmetico",
          descricao_segmento: "Cosmético",
          tamanho: "500",
          unidade_medida: "ml",
          nome: "Pote Plástico 500ml Inovah Bio Rosca",
          descricao: "",
          fotos: {
            capa: require('@/assets/images/products/cover/bio_b_500.png'),
            detalhe: "bio_b_500.png",
            galeria: [
              require('@/assets/images/products/bio_b_500.png'),
              require('@/assets/images/products/cover/bio_b_500.png')
            ]
          },
          url: "pote-plastico-500ml-inovah-bio-rosca-cosmetico",
          detalhes: {
            caracteristica:
                "Bio é um pote de PEAD produzida pela tecnologia a sopro, qualidade incomparável, sua tampa com rosca faz com que você possa abrir e fechar diversas vezes, garantindo assim a segurança de seu produtos.",
            tampa: [
              "Tampa: Rosca (sob consulta)"
            ],
            tecnico: [
              "Gramatura de linha : 26g (demais gramaturas sob consulta)",
              "Material: PEAD (Polietileno de alta densidade) novo ou reciclável",
              "Cores de linha: Natural e branco (demais cores sob consulta)"
            ],
          },
        },
        {
          id: 9,
          repetido: true,
          segmento: "cosmetico",
          descricao_segmento: "Cosmético",
          tamanho: "1000",
          unidade_medida: "ml",
          nome: "Pote Plástico 1000ml Inovah Bio B",
          descricao:
              "Pote plástico para envase de cremes, produtos farmacêuticos, cosméticos e para envase de produtos de limpeza em geral...",
          fotos: {
            capa: require('@/assets/images/products/cover/bio_b_1000.png'),
            detalhe: "bio_b_1000.png",
            galeria: [
              require('@/assets/images/products/bio_b_1000.png'),
              require('@/assets/images/products/cover/bio_b_1000.png')
            ]
          },
          url: "pote-plastico-1000ml-inovah-bio-b-cosmetico",
          detalhes: {
            caracteristica:
                "Bio é um pote de PEAD produzida pela tecnologia a sopro, qualidade incomparável, sua tampa de rosca é indicado para envase de produto o qual necessita ser aberto e fechado inúmeras vezes.",
            aplicacao:
                "Muito procurado no envase de cremes, produtos farmacêuticos, cosméticos e para envase de produtos de limpeza em geral.",
            tecnico:
                "Utilizações: Cosmética, artesanal e escolar | Peso: 47 gramas | Capacidade: 1 quilo | Material: PEAD novo | Tampa: rosca, cor preta | Cores: Natural, branco, cor | Dimensões: 135 mm x 105 mm | Impressões: 100 mm Ø",
          },
        },
        {
          id: 10,
          repetido: true,
          segmento: "cosmetico",
          descricao_segmento: "Cosmético",
          tamanho: "1000",
          unidade_medida: "ml",
          nome: "Pote Plástico 1000ml Inovah Bio A",
          descricao:
              "Pote plástico para envase de produtos farmacêuticos, cremes, cosméticos e para envase de produtos de limpeza em geral...",
          fotos: {
            capa: require('@/assets/images/products/cover/bio_a.png'),
            detalhe: "bio_a.png",
            galeria: [
              require('@/assets/images/products/bio_a.png'),
              require('@/assets/images/products/cover/bio_a.png')
            ]
          },
          url: "pote-plastico-1000ml-inovah-bio-a-cosmetico",
          detalhes: {
            caracteristica:
                "Cili é uma embalagem de PEAD,produzida pela tecnologia a sopro, qualidade incomparável, sua tampa com lacre, é ideal para conservar, garantindo assim a segurança de seu produtos.",
            tampa: [
              "Tampa: Batoque c/ lacre"
            ],
            tecnico: [
              "Gramatura de linha : 45g (demais gramaturas sob consulta)",
              "Material: PEAD (Polietileno de alta densidade) novo ou reciclável",
              "Cores de linha: Natural e branco (demais cores sob consulta)"
            ],
          },
        },
        {
          id: 11,
          repetido: true,
          segmento: "farmaceutico",
          descricao_segmento: "Farmacêutico",
          tamanho: "500",
          unidade_medida: "ml",
          nome: "Frasco Plástico 500ml Cilíndrico Batoque",
          descricao:
              "Frasco plástico usado para aditivos de motor, óleo/silicone para lubrificação, envase de produtos farmacêuticos ...",
          fotos: {
            capa: require('@/assets/images/products/cover/bio_a.png'),
            detalhe: "bio_a.png",
            galeria: [
              require('@/assets/images/products/bio_a.png'),
              require('@/assets/images/products/cover/bio_a.png')
            ]
          },
          url: "frasco-plastico-500ml-cilindrico-batoque-farmaceutico",
          detalhes: {
            caracteristica:
                "Cili é uma embalagem de PEAD,produzida pela tecnologia a sopro, qualidade incomparável, sua tampa com lacre, é ideal para conservar, garantindo assim a segurança de seu produtos.",
            tampa: [
              "Tampa: Batoque c/ lacre"
            ],
            tecnico: [
              "Gramatura de linha : 45g (demais gramaturas sob consulta)",
              "Material: PEAD (Polietileno de alta densidade) novo ou reciclável",
              "Cores de linha: Natural e branco (demais cores sob consulta)"
            ],
          },
        },
        {
          id: 12,
          repetido: true,
          segmento: "farmaceutico",
          descricao_segmento: "Farmacêutico",
          tamanho: "1000",
          unidade_medida: "ml",
          nome: "Frasco Plástico 1000ml Cilíndrico",
          descricao:
              "Frasco plástico para envase de aditivos de motor, produtos farmacêuticos, óleo/silicone para lubrificação, cola branca, produtos químicos e de limpeza em geral ...",
          fotos: {
            capa: require('@/assets/images/products/cover/frasco_cili_1000.png'),
            detalhe: "frasco_cili_1000.png",
            galeria: [
              require('@/assets/images/products/frasco_cili_1000.png'),
              require('@/assets/images/products/cover/frasco_cili_1000.png')
            ]
          },
          url: "frasco-plastico-1000ml-cilindrico-farmaceutico",
          detalhes: {
            caracteristica:
                "Cili é uma embalagem de PEAD produzida pela tecnologia sopro, qualidade incomparável, sua tampa com lacre, é ideal para conservar e evitar vazamentos, garantindo assim a segurança do seu produto.",
            tampa: [
              "Tampa: Batoque c/ lacre"
            ],
            tecnico: [
              "Gramatura de linha : 45g (outras gramaturas sob consulta)",
              "Material: PEAD (Polietileno de alta densidade) Novo ou Reciclável",
              "Cores de linha: Natural e branco (outras cores sob consulta)"
            ],
          },
        },
        {
          id: 13,
          repetido: true,
          segmento: "farmaceutico",
          descricao_segmento: "Farmacêutico",
          tamanho: "1000",
          unidade_medida: "ml",
          nome: "Frasco Plástico 1000ml Quadrado 32",
          descricao:
              "Frasco plástico para envase de aditivos de motor, óleo/silicone para lubrificação, cola branca, produtos farmacêuticos, e produtos químicos e de limpeza em geral...",
          fotos: {
            capa: require('@/assets/images/products/cover/quadrado_32.jpg'),
            detalhe: "quadrado_32.jpg",
            galeria: [
              require('@/assets/images/products/quadrado_32.jpg'),
              require('@/assets/images/products/cover/quadrado_32.jpg')
            ]
          },
          url: "frasco-plastico-1000ml-quadrado-32-farmaceutico",
          detalhes: {
            caracteristica:
                "Frasco plástico 1000ml quadrado 32 é uma embalagem de PEAD produzida pela tecnologia sopro, qualidade incomparável, sua tampa com lacre, é ideal para conservar, garantindo assim a segurança de seu produtos, sendo utilizado também com selo de indução.",
            aplicacao:
                "Muito usado para envase de aditivos de motor, óleo/silicone para lubrificação, cola branca, produtos farmacêuticos, e produtos químicos e de limpeza em geral.",
            tecnico: "",
          },
        },
        {
          id: 14,
          repetido: true,
          segmento: "farmaceutico",
          descricao_segmento: "Farmacêutico",
          tamanho: "500",
          unidade_medida: "ml",
          nome: "Pote Plástico 500ml Inovah Bio Rosca",
          descricao: "",
          fotos: {
            capa: require('@/assets/images/products/cover/bio_b_500.png'),
            detalhe: "bio_b_500.png",
            galeria: [
              require('@/assets/images/products/bio_b_500.png'),
              require('@/assets/images/products/cover/bio_b_500.png')
            ]
          },
          url: "pote-plastico-500ml-inovah-bio-rosca-farmaceutico",
          detalhes: {
            caracteristica:
                "Bio é um pote de PEAD produzida pela tecnologia a sopro, qualidade incomparável, sua tampa com rosca faz com que você possa abrir e fechar diversas vezes, garantindo assim a segurança de seu produtos.",
            tampa: [
              "Tampa: Rosca (sob consulta)"
            ],
            tecnico: [
              "Gramatura de linha : 26g (demais gramaturas sob consulta)",
              "Material: PEAD (Polietileno de alta densidade) novo ou reciclável",
              "Cores de linha: Natural e branco (demais cores sob consulta)"
            ],
          },
        },
        {
          id: 15,
          repetido: true,
          segmento: "farmaceutico",
          descricao_segmento: "Farmacêutico",
          tamanho: "1000",
          unidade_medida: "ml",
          nome: "Pote Plástico 1000ml Inovah Bio B",
          descricao:
              "Pote plástico para envase de cremes, produtos farmacêuticos, cosméticos e para envase de produtos de limpeza em geral...",
          fotos: {
            capa: require('@/assets/images/products/cover/bio_b_1000.png'),
            detalhe: "bio_b_1000.png",
            galeria: [
              require('@/assets/images/products/bio_b_1000.png'),
              require('@/assets/images/products/cover/bio_b_1000.png')
            ]
          },
          url: "pote-plastico-1000ml-inovah-bio-b-farmaceutico",
          detalhes: {
            caracteristica:
                "Bio é um pote de PEAD produzida pela tecnologia a sopro, qualidade incomparável, sua tampa de rosca é indicado para envase de produto o qual necessita ser aberto e fechado inúmeras vezes.",
            aplicacao:
                "Muito procurado no envase de cremes, produtos farmacêuticos, cosméticos e para envase de produtos de limpeza em geral.",
            tecnico:
                "Utilizações: Cosmética, artesanal e escolar | Peso: 47 gramas | Capacidade: 1 quilo | Material: PEAD novo | Tampa: rosca, cor preta | Cores: Natural, branco, cor | Dimensões: 135 mm x 105 mm | Impressões: 100 mm Ø",
          },
        },
        {
          id: 16,
          repetido: true,
          segmento: "farmaceutico",
          descricao_segmento: "Farmacêutico",
          tamanho: "1000",
          unidade_medida: "ml",
          nome: "Pote Plástico 1000ml Inovah Bio A",
          descricao:
              "Pote plástico para envase de produtos farmacêuticos, cremes, cosméticos e para envase de produtos de limpeza em geral...",
          fotos: {
            capa: require('@/assets/images/products/cover/bio_a.png'),
            detalhe: "bio_a.png",
            galeria: [
              require('@/assets/images/products/bio_a.png'),
              require('@/assets/images/products/cover/bio_a.png')
            ]
          },
          url: "pote-plastico-1000ml-inovah-bio-a-farmaceutico",
          detalhes: {
            caracteristica:
                "Bio é um pote de PEAD produzida pela tecnologia a sopro, qualidade incomparável, sua tampa de rosca é indicado para envase de produto o qual necessita ser aberto e fechado inúmeras vezes.",
            tampa: [
              "Tampa: Batoque c/ lacre"
            ],
            tecnico: [
              "Gramatura de linha : 45g (demais gramaturas sob consulta)",
              "Material: PEAD (Polietileno de alta densidade) novo ou reciclável",
              "Cores de linha: Natural e branco (demais cores sob consulta)"
            ],
          },
        },
        {
          id: 17,
          repetido: true,
          segmento: "quimico",
          descricao_segmento: "Química",
          tamanho: "1000",
          unidade_medida: "ml",
          nome: "Frasco Plástico 1000ml Inovah",
          descricao:
              "Frasco plástico para envase de aditivo de radiadores automotivos e óleo/silicone para lubrificação...",
          fotos: {
            capa: require('@/assets/images/products/cover/frasco_inovah_1000.png'),
            detalhe: "frasco_inovah_1000.png",
            galeria: [
              require('@/assets/images/products/frasco_inovah_1000.png'),
              require('@/assets/images/products/cover/frasco_inovah_1000.png')
            ]
          },
          url: "frasco-plastico-1000ml-inovah-quimico",
          detalhes: {
            caracteristica:
                "Frasco Inovah é uma embalagem de PEAD produzida pela tecnologia de sopro, qualidade incomparável. Possui tampa com batoque e lacre, podendo-se também aplicar selo de indução, garantindo assim a segurança do seu produto.",
            tampa: [
              "Tampa: Pressão e Rosca (sob consulta)"
            ],
            tecnico: [
              "Gramatura de linha : 45g (demais gramaturas sob consulta)",
              "Material: PEAD (Polietileno de alta densidade) novo ou reciclável",
              "Cores de linha: Natural e branco (demais cores sob consulta)"
            ],
          },
        },
        {
          id: 18,
          repetido: true,
          segmento: "quimico",
          descricao_segmento: "Química",
          tamanho: "500",
          unidade_medida: "ml",
          nome: "Frasco Plástico 500ml Cilíndrico Batoque",
          descricao:
              "Frasco plástico usado para aditivos de motor, óleo/silicone para lubrificação, envase de produtos farmacêuticos ...",
          fotos: {
            capa: require('@/assets/images/products/cover/frasco_cili_500.png'),
            detalhe: "frasco_cili_500.png",
            galeria: [
              require('@/assets/images/products/frasco_cili_500.png'),
              require('@/assets/images/products/cover/frasco_cili_500.png')
            ]
          },
          url: "frasco-plastico-500ml-cilindrico-batoque-quimico",
          detalhes: {
            caracteristica:
                "Cili é uma embalagem de PEAD,produzida pela tecnologia a sopro, qualidade incomparável, sua tampa com lacre, é ideal para conservar, garantindo assim a segurança de seu produtos.",
            tampa: [
              "Tampa: Batoque c/ lacre"
            ],
            tecnico: [
              "Gramatura de linha : 45g (outras gramaturas sob consulta)",
              "Material: PEAD (Polietileno de alta densidade) Novo ou Reciclável",
              "Cores de linha: Natural e branco (outras cores sob consulta)"
            ],
          },
        },
        {
          id: 19,
          repetido: true,
          segmento: "quimico",
          descricao_segmento: "Química",
          tamanho: "1000",
          unidade_medida: "ml",
          nome: "Frasco Plástico 1000ml Cilíndrico",
          descricao:
              "Frasco plástico para envase de aditivos de motor, produtos farmacêuticos, óleo/silicone para lubrificação, cola branca, produtos químicos e de limpeza em geral ...",
          fotos: {
            capa: require('@/assets/images/products/cover/frasco_cili_1000.png'),
            detalhe: "frasco_cili_1000.png",
            galeria: [
              require('@/assets/images/products/frasco_cili_1000.png'),
              require('@/assets/images/products/cover/frasco_cili_1000.png')
            ]
          },
          url: "frasco-plastico-1000ml-cilindrico-quimico",
          detalhes: {
            caracteristica:
                "Cili é uma embalagem de PEAD produzida pela tecnologia sopro, qualidade incomparável, sua tampa com lacre, é ideal para conservar e evitar vazamentos, garantindo assim a segurança do seu produto.",
            tampa: [
              "Tampa: Batoque c/ lacre"
            ],
            tecnico: [
              "Gramatura de linha : 45g (outras gramaturas sob consulta)",
              "Material: PEAD (Polietileno de alta densidade) Novo ou Reciclável",
              "Cores de linha: Natural e branco (outras cores sob consulta)"
            ],
          },
        },
        {
          id: 20,
          repetido: true,
          segmento: "quimico",
          descricao_segmento: "Química",
          tamanho: "1000",
          unidade_medida: "ml",
          nome: "Frasco Plástico 1000ml Quadrado 32",
          descricao:
              "Frasco plástico para envase de aditivos de motor, óleo/silicone para lubrificação, cola branca, produtos farmacêuticos, e produtos químicos e de limpeza em geral...",
          fotos: {
            capa: require('@/assets/images/products/cover/quadrado_32.jpg'),
            detalhe: "quadrado_32.jpg",
            galeria: [
              require('@/assets/images/products/quadrado_32.jpg'),
              require('@/assets/images/products/cover/quadrado_32.jpg')
            ]
          },
          url: "frasco-plastico-1000ml-quadrado-32-quimico",
          detalhes: {
            caracteristica:
                "Frasco plástico 1000ml quadrado 32 é uma embalagem de PEAD produzida pela tecnologia sopro, qualidade incomparável, sua tampa com lacre, é ideal para conservar, garantindo assim a segurança de seu produtos, sendo utilizado também com selo de indução.",
            aplicacao:
                "Muito usado para envase de aditivos de motor, óleo/silicone para lubrificação, cola branca, produtos farmacêuticos, e produtos químicos e de limpeza em geral.",
            tecnico: "",
          },
        },
        {
          id: 21,
          repetido: false,
          segmento: "galao_5000ml",
          descricao_segmento: "Galão 5000ml",
          tamanho: "5000",
          unidade_medida: "ml",
          nome: "Galão 5.000 ML - Modelo Atlas",
          descricao: "",
          fotos: {
            capa: require('@/assets/images/products/cover/atlas_5000ml.png'),
            detalhe: "atlas_5000ml.png",
            galeria: [
              require('@/assets/images/products/atlas_5000ml.png'),
              require('@/assets/images/products/cover/atlas_5000ml.png')
            ]
          },
          url: "galao_5000ml_modelo_atlas",
          detalhes: {
            caracteristica: `Galão de 5 Litros - Bombona de 5 Litros. Com visor lateral ou Sem visor lateral Materia Prima: PEAD (Polietileno de alta densidade ou reciclável)`,
            tampa: [
                "Tampa: Batoque c/ lacre"
            ],
            tecnico: [
                "Gramatura de linha : 140g (outras gramaturas sob consulta)",
                "Material: PEAD (Polietileno de alta densidade) Novo ou Reciclável",
                "Cores de linha: Natural e branco (outras cores sob consulta)",
                "Frasco com opção de visor lateral"
            ],
          },
        },
        {
          id: 22,
          repetido: false,
          segmento: "galao_5000ml",
          descricao_segmento: "Galão 5000ml",
          tamanho: "5000",
          unidade_medida: "ml",
          nome: "Galão 5.000ml - Modelo Combate",
          descricao: "",
          fotos: {
            capa: require('@/assets/images/products/cover/combate_5000ml.png'),
            detalhe: "combate_5000ml.png",
            galeria: [
              require('@/assets/images/products/combate_5000ml.png'),
              require('@/assets/images/products/cover/combate_5000ml.png')
            ]
          },
          url: "galao_combate_5000ml",
          detalhes: {
            caracteristica: `Galão de 5 Litros - Bombona de 5 Litros. Com visor lateral ou Sem visor lateral Materia Prima: PEAD (Polietileno de alta densidade ou reciclável)`,
            tampa: [
                "Tampa: Batoque c/ lacre"
            ],
            tecnico: [
                "Gramatura de linha : 140g (outras gramaturas sob consulta)",
                "Material: PEAD (Polietileno de alta densidade) Novo ou Reciclável",
                "Cores de linha: Natural e branco (outras cores sob consulta)",
                "Frasco com opção de visor lateral"
            ]
          },
        },
        {
          id: 23,
          repetido: false,
          segmento: "agro_catraca_1000ml",
          descricao_segmento: "Frasco Plástico 1000ml Cilíndrico Catraca",
          tamanho: "1000",
          unidade_medida: "ml",
          nome: "Frasco Plástico 1000ml Cilíndrico Catraca",
          descricao: "",
          fotos: {
            capa: require('@/assets/images/products/cover/agro_catraca_1000ml.png'),
            detalhe: "agro_catraca_1000ml.png",
            galeria: [
              require('@/assets/images/products/agro_catraca_1000ml.png'),
              require('@/assets/images/products/cover/agro_catraca_1000ml.png')
            ]
          },
          url: "agro_catraca_1000ml",
          detalhes: {
            caracteristica: ``,
            tampa: [
                "Tampa: Catraca"
            ],
            tecnico: [
              "Gramaturas de: 45g a 90g ",
              "Material: PEAD (Polietileno de alta densidade) novo ou reciclável",
              "Cores de linha: Natural e branco (demais cores sob consulta)"
            ],
          },
        },
        {
          id: 24,
          repetido: false,
          segmento: "agro_tampa_dosadora_1000ml",
          descricao_segmento: "Agro Tampa Dosadora 1000ml",
          tamanho: "1000",
          unidade_medida: "ml",
          nome: "Agro Tampa Dosadora 1000ml",
          descricao: "",
          fotos: {
            capa: require('@/assets/images/products/cover/agro_tampa_dosadora_1000ml.png'),
            detalhe: "agro_tampa_dosadora_1000ml.png",
            galeria: [
              require('@/assets/images/products/agro_tampa_dosadora_1000ml.png'),
              require('@/assets/images/products/cover/agro_tampa_dosadora_1000ml.png')
            ]
          },
          url: "agro_tampa_dosadora_1000ml",
          detalhes: {
            caracteristica: ``,
            tampa: [
                "Tampa: Dosadora"
            ],
            tecnico: [
                "Gramatura de linha : 45g (demais gramaturas sob consulta)",
                "Material: PEAD (Polietileno de alta densidade) novo ou reciclável",
                "Cores de linha: Natural e branco (demais cores sob consulta)"
            ],
          },
        },
        {
          id: 25,
          repetido: false,
          segmento: "atlas_1000ml",
          descricao_segmento: "Frasco Plástico 1000ml Modelo Atlas",
          tamanho: "1000",
          unidade_medida: "ml",
          nome: "Frasco Plástico 1000ml Modelo Atlas",
          descricao: "",
          fotos: {
            capa: require('@/assets/images/products/cover/atlas_1000ml.png'),
            detalhe: "atlas_1000ml.png",
            galeria: [
              require('@/assets/images/products/atlas_1000ml.png'),
              require('@/assets/images/products/cover/atlas_1000ml.png')
            ]
          },
          url: "atlas_1000ml",
          detalhes: {
            caracteristica: ``,
            tampa: [
                "Tampa: Rosca, Batoque ou Selo"
            ],
            tecnico: [
                "Gramatura de linha : 50g (demais gramaturas sob consulta)",
                "Material: PEAD (Polietileno de alta densidade) novo ou reciclável",
                "Cores de linha: Natural e branco (demais cores sob consulta)"
            ],
          },
        },
        {
          id: 26,
          repetido: false,
          segmento: "automobilistico",
          descricao_segmento: "Automobilísta",
          tamanho: "500",
          unidade_medida: "ml",
          nome: "Frasco Plástico 500ml Inovah",
          descricao:
              "Frasco plástico para envase de aditivo de radiadores automotivos e óleo/silicone para lubrificação...",
          fotos: {
            capa: require('@/assets/images/products/cover/frasco_inovah_500.png'),
            detalhe: "frasco_inovah_500.png",
            galeria: [
              require('@/assets/images/products/frasco_inovah_500.png'),
              require('@/assets/images/products/cover/frasco_inovah_500.png')
            ]
          },
          url: "frasco-plastico-500ml-inovah",
          detalhes: {
            caracteristica:
                "",
            tampa: [
              "Tampa: Pressão e Rosca (sob consulta)"
            ],
            tecnico: [
              "Gramatura de linha : 26g (demais gramaturas sob consulta)",
              "Material: PEAD (Polietileno de alta densidade) novo ou reciclável",
              "Cores de linha: Natural e branco (demais cores sob consulta)"
            ],
          },
        },
        {
          id: 27,
          repetido: false,
          segmento: "standard_5000ml",
          descricao_segmento: "Galão 5.000ml - Modelo standard",
          tamanho: "5000",
          unidade_medida: "ml",
          nome: "Galão 5.000ml - Modelo standard",
          descricao: "",
          fotos: {
            capa: require('@/assets/images/products/cover/standard_5000ml.png'),
            detalhe: "standard_5000ml.png",
            galeria: [
              require('@/assets/images/products/standard_5000ml.png'),
              require('@/assets/images/products/cover/standard_5000ml.png')
            ]
          },
          url: "standard_5000ml",
          detalhes: {
            caracteristica: ``,
            aplicacao: "",
            tecnico: "",
          },
        },
      ],
      produtoEncontrado: null,
      index: null
    };
  },
  created() {

    scroll(0,0);

    this.produtoEncontrado =
        this.produtos.filter(
            (produto) => produto.url == this.$route.params.produto
        )[0] || null;
  },
  methods: {
    onSlideStart() {
      this.sliding = true;
    },
    onSlideEnd() {
      this.sliding = false;
    },
  },
  metaInfo: {
    title: "Detalhes Produtos",
  },

};
</script>

<style>
.tab-active {
  background-color: #152b5e !important;
}
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23ff0000' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23ff0000' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
}

.img-size-fluid {
  width: 700px;
}

@media screen and (max-width: 992px) {
  .img-size-fluid {
    width: 100%;
  }
}
</style>